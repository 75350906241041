import HelpOutline from "@mui/icons-material/HelpOutline"
import { useSelector } from "react-redux"
import { If, Then, Else } from "react-if"
import Box from "@mui/material/Box"

import Menu from "./menu"
import colors from "../utils/colors"
import { Desktop } from "../components/device"
import ChangeLanguageButton from "../molecules/change_language_button"
import useTranslation from "../hooks/use_translation"
import HelpLink from "@/molecules/help_link"

const styles = {
  separator: {
    height: "16px",
    width: "1px",
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  user: {
    fontSize: "18px",
    color: colors.gray60,
    fontWeight: "400",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}

const AppHeaderSide2 = () => {
  const auth = useSelector((state) => state.auth)
  const user = `${auth?.user?.details?.firstName} ${auth?.user?.details?.lastName}`
  const { t } = useTranslation()

  return (
    <Box sx={styles.buttonsContainer}>
      <Desktop>
        <HelpLink
          rel="noopener noreferrer"
          target="_blank"
          href="/support"
        >
          <HelpOutline />
          <Box>{t("help")}</Box>
        </HelpLink>
        <Box sx={styles.separator} />
        <If condition={auth}>
          <Then>
            <Box sx={styles.user}>{user}</Box>
          </Then>
          <Else>
            <Box sx={styles.user}>{t("menu")}</Box>
          </Else>
        </If>
      </Desktop>
      <Menu />
      <ChangeLanguageButton />
    </Box>
  )
}

AppHeaderSide2.propTypes = {}

export default AppHeaderSide2
